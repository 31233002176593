import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withDirectives as _withDirectives, withModifiers as _withModifiers, createElementBlock as _createElementBlock, Transition as _Transition, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function (n) {
  return _pushScopeId("data-v-4dc39724"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  key: 0,
  class: "vab-screen-lock"
};
var _hoisted_2 = {
  class: "vab-screen-lock-content"
};
var _hoisted_3 = {
  class: "vab-screen-lock-content-title"
};
var _hoisted_4 = {
  class: "vab-screen-lock-content-form"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_vab_icon = _resolveComponent("vab-icon");

  var _component_el_avatar = _resolveComponent("el-avatar");

  var _component_el_button = _resolveComponent("el-button");

  var _component_el_input = _resolveComponent("el-input");

  var _component_el_form_item = _resolveComponent("el-form-item");

  var _component_el_form = _resolveComponent("el-form");

  return _openBlock(), _createElementBlock(_Fragment, null, [$setup.theme.showLock ? (_openBlock(), _createBlock(_component_vab_icon, {
    key: 0,
    icon: "lock-line",
    onClick: $setup.handleLock
  })) : _createCommentVNode("v-if", true), $setup.theme.showLock ? (_openBlock(), _createBlock(_Transition, {
    key: 1,
    mode: "out-in",
    name: "fade-transform"
  }, {
    default: _withCtx(function () {
      return [$setup.lock ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        class: "vab-screen-lock-background",
        style: _normalizeStyle({
          background: "fixed url(".concat($setup.background, ") center"),
          backgroundSize: '100% 100%',
          filter: 'blur(10px)'
        })
      }, null, 4
      /* STYLE */
      ), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_avatar, {
        size: 180,
        src: $setup.avatar
      }, null, 8
      /* PROPS */
      , ["src"]), _createVNode(_component_vab_icon, {
        icon: $setup.lockIcon ? 'lock-line' : 'lock-unlock-line'
      }, null, 8
      /* PROPS */
      , ["icon"]), _createTextVNode(" " + _toDisplayString($setup.title) + " " + _toDisplayString($setup.translateTitle('屏幕已锁定')), 1
      /* TEXT */
      )]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_el_form, {
        ref: "formRef",
        model: $setup.form,
        rules: $setup.rules,
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(function () {}, ["prevent"]))
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_form_item, {
            label: "",
            "label-width": 0,
            prop: "password"
          }, {
            default: _withCtx(function () {
              return [_withDirectives((_openBlock(), _createBlock(_component_el_input, {
                modelValue: $setup.form.password,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
                  return $setup.form.password = $event;
                }),
                autocomplete: "off",
                placeholder: "请输出密码123456",
                type: "password"
              }, {
                suffix: _withCtx(function () {
                  return [_createVNode(_component_el_button, {
                    "native-type": "submit",
                    type: "primary",
                    onClick: $setup.handleUnLock
                  }, {
                    default: _withCtx(function () {
                      return [_createVNode(_component_vab_icon, {
                        icon: $setup.lockIcon ? 'lock-line' : 'lock-unlock-line'
                      }, null, 8
                      /* PROPS */
                      , ["icon"]), _createTextVNode(" " + _toDisplayString($setup.translateTitle('解锁')), 1
                      /* TEXT */
                      )];
                    }),
                    _: 1
                    /* STABLE */

                  })];
                }),
                _: 1
                /* STABLE */

              }, 8
              /* PROPS */
              , ["modelValue"])), [[$setup["vFocus"]]])];
            }),
            _: 1
            /* STABLE */

          })];
        }),
        _: 1
        /* STABLE */

      }, 8
      /* PROPS */
      , ["model"])]), _createElementVNode("span", {
        onClick: $setup.randomBackground
      }, _toDisplayString($setup.translateTitle('切换壁纸')), 1
      /* TEXT */
      )])])) : _createCommentVNode("v-if", true)];
    }),
    _: 1
    /* STABLE */

  })) : _createCommentVNode("v-if", true)], 64
  /* STABLE_FRAGMENT */
  );
}