import request from '@/utils/request'

export function getList() {
  return request({
    url: '/system/role/list',
    method: 'get',
  })
}

export function getRouters() {
  return request({
    url: '/getRouters',
    method: 'get',
  })
}
