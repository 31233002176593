import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_vab_icon = _resolveComponent("vab-icon");

  return _openBlock(), _createBlock(_component_vab_icon, {
    class: "fold-unfold",
    icon: $setup.collapse ? 'menu-unfold-line' : 'menu-fold-line',
    onClick: $setup.toggleCollapse
  }, null, 8
  /* PROPS */
  , ["icon", "onClick"]);
}