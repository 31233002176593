import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function (n) {
  return _pushScopeId("data-v-4b7794ee"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "logo",
  style: {
    "height": "32px",
    "width": "32px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _a;

  var _component_el_image = _resolveComponent("el-image");

  var _component_router_link = _resolveComponent("router-link");

  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["logo-container", (_a = {}, _a['logo-container-' + $setup.theme.layout] = true, _a)])
  }, [_createVNode(_component_router_link, {
    style: {
      "display": "flex",
      "justify-content": "center",
      "align-items": "center"
    },
    to: "/"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("span", _hoisted_1, [_createCommentVNode(" 使用自定义svg示例 "), _createCommentVNode("\n        <vab-icon v-if=\"logo\" :icon=\"logo\" is-custom-svg />\n"), _createVNode(_component_el_image, {
        src: require('@/assets/logo.png')
      }, null, 8
      /* PROPS */
      , ["src"])]), _createElementVNode("span", {
        class: _normalizeClass(["title", {
          'hidden-xs-only': $setup.theme.layout === 'horizontal'
        }])
      }, _toDisplayString($setup.title), 3
      /* TEXT, CLASS */
      )];
    }),
    _: 1
    /* STABLE */

  })], 2
  /* CLASS */
  );
}