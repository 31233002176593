import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function (n) {
  return _pushScopeId("data-v-a95dbcd4"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "vab-nav"
};
var _hoisted_2 = {
  class: "left-panel"
};
var _hoisted_3 = {
  class: "right-panel"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_vab_fold = _resolveComponent("vab-fold");

  var _component_vab_icon = _resolveComponent("vab-icon");

  var _component_el_tab_pane = _resolveComponent("el-tab-pane");

  var _component_el_tabs = _resolveComponent("el-tabs");

  var _component_vab_breadcrumb = _resolveComponent("vab-breadcrumb");

  var _component_el_col = _resolveComponent("el-col");

  var _component_vab_error_log = _resolveComponent("vab-error-log");

  var _component_vab_lock = _resolveComponent("vab-lock");

  var _component_vab_search = _resolveComponent("vab-search");

  var _component_vab_notice = _resolveComponent("vab-notice");

  var _component_vab_full_screen = _resolveComponent("vab-full-screen");

  var _component_vab_language = _resolveComponent("vab-language");

  var _component_vab_theme = _resolveComponent("vab-theme");

  var _component_vab_refresh = _resolveComponent("vab-refresh");

  var _component_vab_avatar = _resolveComponent("vab-avatar");

  var _component_el_row = _resolveComponent("el-row");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
    gutter: 15
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_el_col, {
        lg: 12,
        md: 12,
        sm: 12,
        xl: 12,
        xs: 4
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_2, [$props.layout !== 'float' ? (_openBlock(), _createBlock(_component_vab_fold, {
            key: 0
          })) : _createCommentVNode("v-if", true), $props.layout === 'comprehensive' ? (_openBlock(), _createBlock(_component_el_tabs, {
            key: 1,
            modelValue: $setup.tab.data,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return $setup.tab.data = $event;
            }),
            "tab-position": "top",
            onTabClick: $setup.handleTabClick
          }, {
            default: _withCtx(function () {
              return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.routes, function (item, index) {
                return _openBlock(), _createBlock(_component_el_tab_pane, {
                  key: index + item.name,
                  name: item.name
                }, {
                  label: _withCtx(function () {
                    return [item.meta.icon ? (_openBlock(), _createBlock(_component_vab_icon, {
                      key: 0,
                      icon: item.meta.icon,
                      "is-custom-svg": item.meta.isCustomSvg,
                      style: {
                        "min-width": "16px"
                      }
                    }, null, 8
                    /* PROPS */
                    , ["icon", "is-custom-svg"])) : _createCommentVNode("v-if", true), _createElementVNode("span", null, _toDisplayString($setup.translateTitle(item.meta.title)), 1
                    /* TEXT */
                    )];
                  }),
                  _: 2
                  /* DYNAMIC */

                }, 1032
                /* PROPS, DYNAMIC_SLOTS */
                , ["name"]);
              }), 128
              /* KEYED_FRAGMENT */
              ))];
            }),
            _: 1
            /* STABLE */

          }, 8
          /* PROPS */
          , ["modelValue"])) : (_openBlock(), _createBlock(_component_vab_breadcrumb, {
            key: 2,
            class: "hidden-xs-only"
          }))])];
        }),
        _: 1
        /* STABLE */

      }), _createVNode(_component_el_col, {
        lg: 12,
        md: 12,
        sm: 12,
        xl: 12,
        xs: 20
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_3, [_createVNode(_component_vab_error_log), _createVNode(_component_vab_lock), _createVNode(_component_vab_search), _createVNode(_component_vab_notice), _createVNode(_component_vab_full_screen), _createVNode(_component_vab_language), _createVNode(_component_vab_theme), _createVNode(_component_vab_refresh), _createVNode(_component_vab_avatar)])];
        }),
        _: 1
        /* STABLE */

      })];
    }),
    _: 1
    /* STABLE */

  })]);
}