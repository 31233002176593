import request from '@/utils/request'

export function getList(params: any ) {
  return request({
    url: '/system/notice/list',
    method: 'get',
    params,
  })
}

//修改
export function doEdit(data: any ) {
  return request({
    url: '/system/notice',
    method: 'post',
    data,

  })
}

export function doAdd(data:any){
  return request ({
    url:'/system/notice',
    method:'put',
    data,
  })
}

export function doDelete(noticeIds: any) {
  return request({
    url: '/system/notice/' + noticeIds,
    method: 'delete',
  })
}
