import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, withModifiers as _withModifiers, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function (n) {
  return _pushScopeId("data-v-19e042ab"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_vab_icon = _resolveComponent("vab-icon");

  var _component_el_autocomplete = _resolveComponent("el-autocomplete");

  var _component_el_form_item = _resolveComponent("el-form-item");

  var _component_el_form = _resolveComponent("el-form");

  var _component_el_dialog = _resolveComponent("el-dialog");

  return $setup.theme.showSearch ? (_openBlock(), _createElementBlock("span", _hoisted_1, [_createVNode(_component_vab_icon, {
    icon: "search-line",
    onClick: $setup.openDialog
  }), _createVNode(_component_el_dialog, {
    modelValue: $setup.state.dialogVisible,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return $setup.state.dialogVisible = $event;
    }),
    width: '40%'
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_el_form, {
        model: $setup.state.queryForm,
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(function () {}, ["prevent"]))
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_form_item, {
            "label-width": "0"
          }, {
            default: _withCtx(function () {
              return [_withDirectives((_openBlock(), _createBlock(_component_el_autocomplete, {
                modelValue: $setup.state.queryForm.searchWord,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
                  return $setup.state.queryForm.searchWord = $event;
                }),
                "fetch-suggestions": $setup.querySearchAsync,
                "select-when-unmatched": "",
                onSelect: $setup.handleSelect
              }, {
                prefix: _withCtx(function () {
                  return [_createVNode(_component_vab_icon, {
                    icon: "search-line"
                  })];
                }),
                _: 1
                /* STABLE */

              }, 8
              /* PROPS */
              , ["modelValue"])), [[$setup["vFocus"]]])];
            }),
            _: 1
            /* STABLE */

          })];
        }),
        _: 1
        /* STABLE */

      }, 8
      /* PROPS */
      , ["model"])];
    }),
    _: 1
    /* STABLE */

  }, 8
  /* PROPS */
  , ["modelValue"])])) : _createCommentVNode("v-if", true);
}