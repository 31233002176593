import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function (n) {
  return _pushScopeId("data-v-5274904a"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "notice-list"
};
var _hoisted_2 = ["innerHTML"];
var _hoisted_3 = {
  class: "notice-list"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_vab_icon = _resolveComponent("vab-icon");

  var _component_el_avatar = _resolveComponent("el-avatar");

  var _component_el_scrollbar = _resolveComponent("el-scrollbar");

  var _component_el_tab_pane = _resolveComponent("el-tab-pane");

  var _component_el_tabs = _resolveComponent("el-tabs");

  var _component_el_button = _resolveComponent("el-button");

  var _component_el_popover = _resolveComponent("el-popover");

  var _component_el_badge = _resolveComponent("el-badge");

  return $setup.theme.showNotice ? (_openBlock(), _createBlock(_component_el_badge, {
    key: 0,
    type: "danger",
    value: $setup.badge
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_el_popover, {
        placement: "bottom",
        trigger: "hover",
        width: 300
      }, {
        reference: _withCtx(function () {
          return [_createVNode(_component_vab_icon, {
            icon: "notification-line"
          })];
        }),
        default: _withCtx(function () {
          return [_createVNode(_component_el_tabs, {
            modelValue: $setup.activeName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return $setup.activeName = $event;
            }),
            onTabClick: $setup.handleClick
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_el_tab_pane, {
                label: $setup.translateTitle('通知'),
                name: "notice"
              }, {
                default: _withCtx(function () {
                  return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_scrollbar, null, {
                    default: _withCtx(function () {
                      return [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.notices, function (item, index) {
                        return _openBlock(), _createElementBlock("li", {
                          key: index
                        }, [_createVNode(_component_el_avatar, {
                          size: 45,
                          src: item.image
                        }, null, 8
                        /* PROPS */
                        , ["src"]), _createElementVNode("span", {
                          innerHTML: item.notice
                        }, null, 8
                        /* PROPS */
                        , _hoisted_2)]);
                      }), 128
                      /* KEYED_FRAGMENT */
                      ))])];
                    }),
                    _: 1
                    /* STABLE */

                  })])];
                }),
                _: 1
                /* STABLE */

              }, 8
              /* PROPS */
              , ["label"]), _createVNode(_component_el_tab_pane, {
                label: $setup.translateTitle('邮件'),
                name: "email"
              }, {
                default: _withCtx(function () {
                  return [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_scrollbar, null, {
                    default: _withCtx(function () {
                      return [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.notices, function (item, index) {
                        return _openBlock(), _createElementBlock("li", {
                          key: index
                        }, [_createVNode(_component_el_avatar, {
                          size: 45,
                          src: item.image
                        }, null, 8
                        /* PROPS */
                        , ["src"]), _createElementVNode("span", null, _toDisplayString(item.email), 1
                        /* TEXT */
                        )]);
                      }), 128
                      /* KEYED_FRAGMENT */
                      ))])];
                    }),
                    _: 1
                    /* STABLE */

                  })])];
                }),
                _: 1
                /* STABLE */

              }, 8
              /* PROPS */
              , ["label"])];
            }),
            _: 1
            /* STABLE */

          }, 8
          /* PROPS */
          , ["modelValue"]), _createElementVNode("div", {
            class: "notice-clear",
            onClick: $setup.handleClearNotice
          }, [_createVNode(_component_el_button, {
            text: "",
            type: "primary"
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_vab_icon, {
                icon: "close-circle-line"
              }), _createElementVNode("span", null, _toDisplayString($setup.translateTitle('清空消息')), 1
              /* TEXT */
              )];
            }),
            _: 1
            /* STABLE */

          })])];
        }),
        _: 1
        /* STABLE */

      })];
    }),
    _: 1
    /* STABLE */

  }, 8
  /* PROPS */
  , ["value"])) : _createCommentVNode("v-if", true);
}