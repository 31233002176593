import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_vab_icon = _resolveComponent("vab-icon");

  return $setup.theme.showFullScreen ? (_openBlock(), _createBlock(_component_vab_icon, {
    key: 0,
    icon: $setup.isFullscreen ? 'fullscreen-exit-fill' : 'fullscreen-fill',
    onClick: $setup.toggle
  }, null, 8
  /* PROPS */
  , ["icon", "onClick"])) : _createCommentVNode("v-if", true);
}