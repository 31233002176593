import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function (n) {
  return _pushScopeId("data-v-1c52d0c6"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "vab-main"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_vab_side_bar = _resolveComponent("vab-side-bar");

  var _component_vab_nav = _resolveComponent("vab-nav");

  var _component_vab_tabs = _resolveComponent("vab-tabs");

  var _component_vab_app_main = _resolveComponent("vab-app-main");

  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["vab-layout-float", {
      fixed: $props.fixedHeader,
      'no-tabs-bar': !$props.showTabs
    }])
  }, [_createVNode(_component_vab_side_bar, {
    layout: "float"
  }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass(["vab-layout-header", {
      'fixed-header': $props.fixedHeader
    }])
  }, [_createVNode(_component_vab_nav, {
    layout: "float"
  }), _withDirectives(_createVNode(_component_vab_tabs, null, null, 512
  /* NEED_PATCH */
  ), [[_vShow, $props.showTabs]])], 2
  /* CLASS */
  ), _createVNode(_component_vab_app_main)])], 2
  /* CLASS */
  );
}