import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function (n) {
  return _pushScopeId("data-v-76a43974"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_vab_icon = _resolveComponent("vab-icon");

  var _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item");

  var _component_el_breadcrumb = _resolveComponent("el-breadcrumb");

  return _openBlock(), _createBlock(_component_el_breadcrumb, {
    class: "vab-breadcrumb",
    separator: ">"
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.breadcrumbList, function (item, index) {
        return _openBlock(), _createBlock(_component_el_breadcrumb_item, {
          key: index,
          to: $setup.handleTo(item.redirect)
        }, {
          default: _withCtx(function () {
            return [item.meta.icon ? (_openBlock(), _createBlock(_component_vab_icon, {
              key: 0,
              icon: item.meta.icon,
              "is-custom-svg": item.meta.isCustomSvg
            }, null, 8
            /* PROPS */
            , ["icon", "is-custom-svg"])) : _createCommentVNode("v-if", true), item.meta.title ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString($setup.translateTitle(item.meta.title)), 1
            /* TEXT */
            )) : _createCommentVNode("v-if", true)];
          }),
          _: 2
          /* DYNAMIC */

        }, 1032
        /* PROPS, DYNAMIC_SLOTS */
        , ["to"]);
      }), 128
      /* KEYED_FRAGMENT */
      ))];
    }),
    _: 1
    /* STABLE */

  });
}