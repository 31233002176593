import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_vab_logo = _resolveComponent("vab-logo");

  var _component_vab_menu = _resolveComponent("vab-menu");

  var _component_el_menu = _resolveComponent("el-menu");

  var _component_el_scrollbar = _resolveComponent("el-scrollbar");

  return _openBlock(), _createBlock(_component_el_scrollbar, {
    class: _normalizeClass(["vab-side-bar", {
      'is-collapse': $setup.collapse,
      'side-bar-common': $props.layout === 'common'
    }])
  }, {
    default: _withCtx(function () {
      return [$props.layout === 'vertical' || $props.layout === 'comprehensive' || $props.layout === 'float' ? (_openBlock(), _createBlock(_component_vab_logo, {
        key: 0
      })) : _createCommentVNode("v-if", true), _createVNode(_component_el_menu, {
        "active-text-color": $setup.variables['menu-color-active'],
        "background-color": $setup.variables['menu-background'],
        collapse: $setup.collapse,
        "collapse-transition": false,
        "default-active": $setup.activeMenu.data,
        "default-openeds": $setup.defaultOpeneds,
        "menu-trigger": "click",
        mode: "vertical",
        "text-color": $setup.variables['menu-color'],
        "unique-opened": $setup.uniqueOpened
      }, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.handleRoutes, function (item, index) {
            return _openBlock(), _createElementBlock(_Fragment, {
              key: index + item.name
            }, [!item.meta.hidden ? (_openBlock(), _createBlock(_component_vab_menu, {
              key: 0,
              item: item
            }, null, 8
            /* PROPS */
            , ["item"])) : _createCommentVNode("v-if", true)], 64
            /* STABLE_FRAGMENT */
            );
          }), 128
          /* KEYED_FRAGMENT */
          ))];
        }),
        _: 1
        /* STABLE */

      }, 8
      /* PROPS */
      , ["active-text-color", "background-color", "collapse", "default-active", "default-openeds", "text-color", "unique-opened"])];
    }),
    _: 1
    /* STABLE */

  }, 8
  /* PROPS */
  , ["class"]);
}